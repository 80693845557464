import React from "react";
import "./Channels.css";
import EventVerticalList from "../../components/EventVerticalCollection";
import { useLocation, useParams } from "react-router-dom";
import useFocusParent from "../../hooks/useFocusParent";
import { useEffect, useState, useContext } from "react";
import { makeRequest } from "../../utils/fetchData";
import SliderList from "../../components/Slider/SliderList";
import BBCPlayerLogo from "../../assets/images/logo-player.png";
import BBCEarthLogo from "../../assets/images/bbc-earth.png";
import { removeStorageItem } from "../../utils/utils";
import { AstroBBCContext } from "../../App";
import { memo } from "react";

const Channels = ({
  hasFocus,
  bubbleFocusUP,
  changeFocusOwnerApp,
  toggleBack,
  setPlayerSource,
  showAstroInfo,
  setShowAstroInfo,
}) => {
  const appData = useContext(AstroBBCContext);
  const [components, setComponents] = useState([]);
  const { state } = useLocation();
  const { data = "" } = state || {};
  const { path } = useParams();
  const [metadata, setMetadata] = useState({
    name: "",
    description: "",
    image: "",
  });

  useEffect(() => {}, [state]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_MIDDLEWARE_URL}/contentitem/channel/${path}`;
    appData.showLoading(true);
    let header = null;
    if (appData.auth.isUserSignedIn()) {
      header = appData.auth.getAuthHeader();
      console.log(header);
    }
    makeRequest(url, "GET", null, header)
      .then((data) => {
        if (data.contentItem) {
          const components = data.contentItem.components;
          setMetadata((metadata) => ({
            name: data.contentItem.name,
            description: data.contentItem.description,
            image: data.contentItem.tile.image.url,
          }));

          const promises = [];
          const metadataMap = {};
          const unfiltered = components;
          let metaDataFetchIndex = 0;
          unfiltered.forEach((item, index) => {
            if (item?.viewAll !== "" && item.viewAll !== undefined) {
              let viewAllURL =
                `${process.env.REACT_APP_MIDDLEWARE_URL}/contentitem` +
                item.viewAll;
              promises.push(makeRequest(viewAllURL, "GET", null, header));
              metadataMap[item?.header] = metaDataFetchIndex;
              metaDataFetchIndex++;
            }
          });

          Promise.all(promises)
            .then((metadatas) => {
              unfiltered.forEach((element, i, self) => {
                if (element?.viewAll !== "" && element.viewAll !== undefined) {
                  let current = element;
                  let currentIndex = metadataMap[element?.header];
                  current.collection.items.nodes =
                    metadatas[
                      currentIndex
                    ].contentItem?.components[0]?.collection?.items?.nodes;
                  unfiltered[i] = current;
                  console.log(
                    metadatas[currentIndex].contentItem?.components[0]
                      ?.collection?.items?.nodes
                  );
                }
              });
              if (components[0].__typename === "Live") {
                components.shift();
              }
              setComponents(components);
              appData.showLoading(false);
            })
            .catch((e) => {
              // handle errors here
            });
        }
      })
      .catch((err) => console.log(err));
  }, [state]);

  let focusNavMap = {
    eventList: {},
    Categories: {
      LEFT: "SideMenu",
    },
    SliderList: {},
  };

  const [
    currentFocusOwner,
    setFocusRequest,
    changeFocusOwner,
    unhandledAction,
    setUnHandledAction,
  ] = useFocusParent(
    "",
    focusNavMap,
    null,
    null,
    null,
    toggleBack,
    showAstroInfo,
    setShowAstroInfo
  );
  useEffect(() => {
    console.log("Channels focus gain");
    if (hasFocus) {
      changeFocusOwner("SliderList");
    } else {
      changeFocusOwner("");
      setUnHandledAction("");
    }
  }, [hasFocus]);

  useEffect(() => {
    if (unhandledAction !== "") {
      bubbleFocusUP({ relieveFocus: true, action: unhandledAction });
    }
  }, [unhandledAction]);

  return (
    <div
      className={
        hasFocus
          ? "channels-screen removeChannelsMargin"
          : "channels-screen addChannelsMargin"
      }
    >
      <div className="channels-title-items-container">
        <div className="channels-bbc-logo-container">
          <img className="channels-bbc-logo" src={BBCPlayerLogo} />
        </div>
        <div className="channels-info-container">
          <div className="channels-text-container">
            <p className="channels-title">{metadata.name}</p>
            <p className="channels-description">{metadata.description}</p>
          </div>
          <div className="channels-spec-logo-container">
            <img className="channels-spec-logo" src={metadata.image} />
          </div>
        </div>
      </div>
      <div className="channels-horizontal-line"></div>
      {/*  <EventVerticalList hasFocus={currentFocusOwner == "eventList"} bubbleFocusUP={setFocusRequest}  /> */}
      <div className="channels-carousal-list">
        <SliderList
          hasFocus={currentFocusOwner === "SliderList"}
          source="Channels"
          bubbleFocusUP={setFocusRequest}
          isSelected={true}
          components={components}
          setPlayerSource={setPlayerSource}
          holdFocus={false}
          changeFocusOwnerApp={changeFocusOwnerApp}
        />
      </div>
    </div>
  );
};

export default memo(Channels);
