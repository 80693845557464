import "./Splash.css";
import Logo from "../../assets/images/logo-player.png";
import useRemoteKeyEvent from "../../hooks/useRemoteKeyEvent";
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AstroBBCContext } from "../../App";
import AstroMoreInfoImage from "../../assets/background/Visual_Non-Entitled_Page_KV.png";

const Splash = (props) => {
  const navigate = useNavigate();
  const appData = useContext(AstroBBCContext);
  const [showAstroInfo, setShowAstroInfo] = useState(false);
  const [showLoginPage, setShowLoginPage] = useState(false);
  const isLoggedInUser = appData.auth.isUserSignedIn();
  const location = useLocation();
  const locationState = location?.state?.pathname ;

  setTimeout(() => {
    setShowLoginPage(true);
  }, 1500);

  let [focusPoint, setFocusPoint] = useState({ x: 0, y: 0 });
  const [keyState, setFocus] = useRemoteKeyEvent(
    ["DOWN", "UP", "OK", "RIGHT", "LEFT", "BACK"],
    props.bubbleFocusUP,
    props.hasFocus
  );
  let splashKeys = [
    [
      {
        keycode: "Sign in with Astro",
        xIndex: 0,
        yIndex: 0,
        flow: "qrcode",
        path: "",
      },
      {
        keycode: "Sign in later",
        xIndex: 1,
        yIndex: 0,
        flow: "later",
        path: "/home",
      },
    ],
    [
      {
        keycode: "How to get BBC with your Astro ID",
        xIndex: 0,
        yIndex: 1,
        span: 2,
        flow: "more",
        path: "",
      },
    ],
  ];

  const [userLoggedIn, setUserLoggedIn] = useState(false);

  useEffect(() => {
    // appData.showLoading(true)
    appData.showLoading(false)
    // navigate('/home', { replace: true } )
    // props.changeFocusOwnerApp('SideMenu')

    if (
      props.hasFocus ||
      (!isLoggedInUser && location?.pathname?.includes("/"))
    ) {
      if (appData.auth.isUserSignedIn()) {
        appData.showLoading(true);
        navigate("/home", { replace: true });
        props.changeFocusOwnerApp("SideMenu");
      } else {
        setFocusPoint({ x: 0, y: 0 });
        setFocus();
        console.log("Splash Gained Focus");
      }
    } else {
      setFocusPoint({ x: -1, y: -1 });
      console.log("Splash Lost Focus");
    }
  }, [props.hasFocus]);

  useEffect(() => {
    let currentFocuspoint = focusPoint;
    if (keyState.RIGHT) {
      if (currentFocuspoint.x < 1 && currentFocuspoint.y < 1) {
        let newFocusPoint = {};
        newFocusPoint.x = currentFocuspoint.x + 1;
        newFocusPoint.y = currentFocuspoint.y;
        setFocusPoint(newFocusPoint);
      }
    } else if (keyState.LEFT) {
      if (currentFocuspoint.x === 1) {
        let newFocusPoint = {};
        newFocusPoint.x = currentFocuspoint.x - 1;
        newFocusPoint.y = currentFocuspoint.y;
        setFocusPoint(newFocusPoint);
      }
    } else if (keyState.DOWN) {
      if (currentFocuspoint.x === 0 && currentFocuspoint.y === 0) {
        let newFocusPoint = {};
        newFocusPoint.x = currentFocuspoint.x;
        newFocusPoint.y = currentFocuspoint.y + 1;
        setFocusPoint(newFocusPoint);
      } else if (currentFocuspoint.x === 1 && currentFocuspoint.y === 0) {
        let newFocusPoint = {};
        newFocusPoint.x = 0;
        newFocusPoint.y = 1;
        setFocusPoint(newFocusPoint);
      }
    } else if (keyState.UP) {
      if (currentFocuspoint.y === 1) {
        let newFocusPoint = {};
        newFocusPoint.x = currentFocuspoint.x;
        newFocusPoint.y = currentFocuspoint.y - 1;
        setFocusPoint(newFocusPoint);
      }
    } else if (keyState.OK) {
      let key = splashKeys[focusPoint.y][focusPoint.x];
      appLaunchRequest(key);
    } else if (keyState.BACK) {
      if (showAstroInfo) {
        setShowAstroInfo(false);
      }
    }
  }, [keyState]);

  const appLaunchRequest = (key) => {
    switch (key.flow) {
      case "qrcode":
        appQRCodeSignIn();
        break;
      case "later":
        appLaunchDirect(key);
        break;
      case "more":
        appMoreInfoRedirect();
        break;

      default:
        break;
    }
  };

  const appLaunchDirect = (key) => {
    appData.showLoading(true);
    navigate(key.path, { replace: true });
    props.changeFocusOwnerApp("SideMenu");
  };

  const appQRCodeSignIn = () => {
    console.log("qr code");
    console.log(appData);
    let url = '';
    if(locationState){
      url = `${process.env.REACT_APP_EVERGENT_URL}/signin?client_id=rocket_ANDROID&redirectURI=${process.env.REACT_APP_PUBLIC_URL}${locationState}&deviceId==${appData.deviceData.deviceType}&deviceName=tv&deviceSerial=${appData.deviceData.deviceID}&operator_name=astro&response_type=code&state=XXXXX-95303-XXXX&key=334c894b34177254d9c999993617f50844370667e8f2897cd8e79137dd492e7a6`;
    }else{
      url = `${process.env.REACT_APP_EVERGENT_URL}/signin?client_id=rocket_ANDROID&redirectURI=${process.env.REACT_APP_PUBLIC_URL}/home&deviceId==${appData.deviceData.deviceType}&deviceName=tv&deviceSerial=${appData.deviceData.deviceID}&operator_name=astro&response_type=code&state=XXXXX-95303-XXXX&key=334c894b34177254d9c999993617f50844370667e8f2897cd8e79137dd492e7a6`;
    }
    debugger
    window.location.href = url;
    props.changeFocusOwnerApp("SideMenu");
  };

  const appMoreInfoRedirect = () => {
    console.log("more info");
    setShowAstroInfo(true);
  };

  return (
    <div>
      <div className="splash-container">
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <img className="logo" src={Logo}></img>
        <br></br>
        <br></br>
        <div className="message">Welcome to BBC Player!</div>
      </div>
      {showLoginPage && !appData.auth.isUserSignedIn() && (
        <div className="splash-container">
          <img className="logo" src={Logo}></img>
          <div className="message">
            Stream and watch the very best of British television
          </div>
          <div className="splashButtonPanel">
            {splashKeys.map((row, rowIndex) => {
              return row.map((item, colIndex) => {
                let style = "splashButtonDiv";

                if (item.span === 2) {
                  style = style + " hyperLink";
                }

                if (item.keycode === "Sign in with Astro") {
                  style = style + " signIn";
                }

                if (item.keycode === "Sign in later") {
                  style = style + " later";
                }

                if (
                  item.xIndex === focusPoint.x &&
                  item.yIndex === focusPoint.y
                ) {
                  if (item.keycode === "How to get BBC with your Astro ID") {
                    style = style + " labelFocus";
                  } else {
                    style = style + "  focused";
                  }
                }

                return (
                  <div
                    key={"key" + rowIndex + "_" + colIndex}
                    className={style}
                  >
                    {item.keycode}
                  </div>
                );
              });
            })}
          </div>
          {showAstroInfo && (
            <div className="astro-more-info-container">
              <img className="astro-info-image" src={AstroMoreInfoImage} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Splash;
