import "./HeroSlider.css";
import { useEffect, useState, useContext } from "react";
import AstroButton from "../button/AstroButton";
import useRemoteKeyEvent from "../../hooks/useRemoteKeyEvent";
import { isClickableInput } from "@testing-library/user-event/dist/utils";
import { useNavigate } from "react-router-dom";
import { SVG } from "../../assets";
import { AstroBBCContext } from "../../App";
import { memo } from "react";
import Modal from "../Modals/Modal";
import { useLocation } from "react-router-dom";
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from "../../utils/utils";
const HeroSlider = ({
  selectedCaurosalIndex,
  hasFocus,
  bubbleFocusUP,
  holdFocus,
  unhandledAction,
  source,
  changeFocusOwnerApp,
  setPlayerSource,
  suppressKeys,
  metadata,
  suppressList,
}) => {
  const appData = useContext(AstroBBCContext);
const location = useLocation();


  useEffect(() => {
    if (!appData.showAstroInfo) {
      setSuppressed(false);
      if (suppressList) {
        suppressList(false);
      }
    }
  }, [appData.showAstroInfo]);

  function usePageViews() {
    let location = useLocation();

    useEffect(() => {
      console.log(location.pathname);
      console.log("location.pathname");
    }, [location]);
  }

  const navigate = useNavigate();

  const nextPath = (path) => {
    console.log(path);
    // setColapsed(true)
    navigate(path);
  };
  const dummyFunc = () => {};
  bubbleFocusUP = holdFocus ? dummyFunc : bubbleFocusUP;
  const [keyState, setFocus, setSuppressed] = useRemoteKeyEvent(
    ["LEFT", "RIGHT", "OK", "BACK"],
    bubbleFocusUP,
    hasFocus
  );
  const [currentFocus, setCurrentFocus] = useState("");
  const [seriesPath, setSeriesPath] = useState("");
  const [isEpisode, setIsEpisode] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [playableMeta, setPlayableMeta] = useState(null);

  useEffect(() => {
    if (metadata) {
      let path = "";
      let currentContent = metadata?.collection?.items?.nodes[0];
      let heroType = currentContent?.__typename;
      setDescription(currentContent?.description);
      switch (heroType) {
        case "Series":
          path = currentContent?.path;
          setTitle(currentContent?.name);
          let sub = currentContent?.seasonCount + " Season";
          setSubtitle(sub);
          setPlayableMeta(currentContent?.firstEpisode);
          break;
        case "Season":
          path = currentContent?.series?.path;
          setTitle(currentContent?.name);
          let subEpisode = currentContent?.episodeCount + " Episodes";
          setSubtitle(subEpisode);
          setPlayableMeta(currentContent?.firstEpisode);
          break;
        case "Episode":
          setIsEpisode(true);
          setSubtitle(currentContent?.name);
          setTitle(currentContent?.season?.name);
          setPlayableMeta(currentContent);
          break;

        default:
          break;
      }

      setSeriesPath(path);
    }
  }, [metadata]);

  useEffect(() => {
    if (suppressKeys) {
      setSuppressed(suppressKeys);
    } else {
      setSuppressed(suppressKeys);
    }
  }, [suppressKeys]);

  useEffect(() => {
    if (keyState.RIGHT) {
      if (currentFocus === "Play" && !isEpisode) {
        setCurrentFocus("More");
      }
    }

    if (keyState.LEFT) {
      if (currentFocus === "More") {
        setCurrentFocus("Play");
      } else {
        bubbleFocusUP({ relieveFocus: true, action: "LEFT" });
      }
    }

    if (keyState.OK) {
      setStorageItem(
        "savedCaurosalIndex",
        selectedCaurosalIndex,
        false,
        false,
        false
      );
      if (currentFocus === "Play") {
        if (appData.auth.hasActiveSubscription(playableMeta)) {
          appData.auth.hasAuthorizedPackage(playableMeta).then((data) => {
            if (data) {
              console.log("Show player");
              changeFocusOwnerApp("Player");
              setPlayerSource(source);
              playableMeta.slide = metadata?.collection?.items?.nodes[0]?.slide;
              let menuFocusDetails = getStorageItem(
                "sidemenu-focus-details",
                false,
                true
              );
              if (menuFocusDetails) {
                menuFocusDetails.playerDirect = true;
                setStorageItem(
                  "sidemenu-focus-details",
                  menuFocusDetails,
                  false,
                  true
                );
              }
              navigate("/player", {
                state: {
                  title: "Show title",
                  subtitle: "Subtitle",
                  source: source,
                  metadata: playableMeta,
                },
              });
            } else {
              setIsOpen(true);
              setSuppressed(true);
              if (suppressList) {
                suppressList(true);
              }
            }
          });
          //
        } else {
          //show pop up
          if (appData.auth.isUserSignedIn()) {
            setIsOpen(true);
            setSuppressed(true);
            if (suppressList) {
              suppressList(true);
            }
            //navigate('/player', { state: { title: "Show title", subtitle: "Subtitle", metadata : selectedItem } });
          } else {
            navigate("/",{state:{pathname:location?.pathname}});
            changeFocusOwnerApp("Splash");
          }
        }
      } else {
        console.log("Show more");
        changeFocusOwnerApp("Brand");

        navigate(`/brand${seriesPath}`, {
          state: { path: seriesPath, source: source },
        });
      }
    }
  }, [keyState]);

  const onUnHandledAction = (action) => {
    console.log(action);
  };

  useEffect(() => {
    if (hasFocus) {
      setFocus();
      setCurrentFocus("Play");
    } else {
      setCurrentFocus("");
    }
  }, [hasFocus]);

  const dummy = () => {};
  const [isOpen, setIsOpen] = useState(false);

  const redirectToSignIn = () => {
    //changeFocusOwnerApp("Splash")
    //navigate('/');
    setIsOpen(false);
    suppressList(true);
    setSuppressed(true);
    appData.displayAstroInfo(true);
  };

  return (
    <div className="heroslider-container">
      <div className="heroslider-details-container">
        <p className="heroslider-title">{title}</p>
        <p className="heroslider-subtitle">{subtitle}</p>
        <p className="heroslider-description">{description} </p>
        <div className="heroslider-button-container">
          <AstroButton
            hasFocus={currentFocus === "Play"}
            data={{ text: "Play", Icon: SVG.Play, customClass: "play" }}
            bubbleFocusUP={dummy}
          />

          {!isEpisode && (
            <AstroButton
              hasFocus={currentFocus === "More"}
              data={{ text: "More Info" }}
              bubbleFocusUP={dummy}
            />
          )}
        </div>
        <Modal
          iswhiteBg={true}
          open={isOpen}
          hasFocus={isOpen}
          onClose={redirectToSignIn}
          showBtwo={true}
          buttonOneText={"Upgrade now"}
          buttonTwoText={"Upgrade later"}
          onCancel={() => {
            setIsOpen(false);
            setSuppressed(false);
            if (suppressList) {
              suppressList(false);
            }
          }}
          title={
            "You currently don't have the right pack to watch this content"
          }
        ></Modal>
      </div>
      <div className="heroslider-image-container">
        <img
          className="heroslider-image"
          src={metadata?.collection?.items?.nodes[0]?.slide?.image?.url}
        />
      </div>
    </div>
  );
};

export default memo(HeroSlider);
