import React, { useState, useEffect, createContext } from "react";
import { Route, Routes } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

//screens
import Splash from "./screens/Login/Splash";
import Home from "./screens/Home/Home";
import Search from "./screens/Search/Search";
import Settings from "./screens/Settings/Settings";
import Channels from "./screens/Channels/Channels";
import Series from "./screens/Series/Series";
import Categories from "./screens/Categories/Categories";
import Player from "./screens/Player/Player";
import RouteWithSideMenu from "./components/RouteWithSideMenu";

import ErrorBoundary from "./screens/Player/Error";
//custom hooks
import useFocusParent from "./hooks/useFocusParent";
import useAuth from "./hooks/useAuth";

//utils
import registerForKeyEvents from "./utils/KeyHandler";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import { requestExitApp } from "./utils/AstroAPI";
import getDeviceInfo from "./utils/AstroAPI";
import { makeRequest } from "./utils/fetchData";
import { titleCase, setStorageItem, getStorageItem } from "./utils/utils";

//Modal
import Modal from "./components/Modals/Modal";

//css
import "./App.css";

import AstroMoreInfoImage from "./assets/background/Visual_Non-Entitled_Page_KV.png";

//Astro app context
export const AstroBBCContext = createContext();

function App() {
  const [appUserData, setAppUserData] = useState({ accessToken: "" });
  const [appDeviceInfo, setAppDeviceInfo] = useState({
    deviceID: "",
    deviceType: "",
  });
  const [showAstroInfo, setShowAstroInfo] = useState(false);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const user = useAuth(appUserData, appDeviceInfo);
  const location = useLocation();

  useEffect(() => {
    registerForKeyEvents();

    let hrefUrl = new URL(window.location.href);
    let code = hrefUrl.searchParams.get("code");

    const url = `${process.env.REACT_APP_MIDDLEWARE_URL}/evergent/bbc/getToken`;
    const body = {
      GetOAuthAccessTokenReqMessage: {
        authCode: code,
        grantType: "authorization_code",
        operator: "astro",
      },
    };
    console.log(url);
    console.log(code);

    getDeviceInfo().then((data) => {
      console.log(data);
      if (data === undefined) {
        //use random id as device id
        //on desktop
        const array = new Uint32Array(2);
        let ids = crypto.getRandomValues(array);
        setAppDeviceInfo({ deviceID: ids[0], deviceType: ids[1] });
        //setAppDeviceInfo({ deviceID: "1680050974", deviceType: "149-238-96" })
      } else {
        setAppDeviceInfo({
          deviceID: data.result.deviceID,
          deviceType: data.result.deviceType,
        });
      }
    });

    //is user token is saved locally and is valid
    //skip to home

    // 1 . get token info from localStorage
    // 2 . check if expired
    // 3 . if not pass token around
    // 4 . fetch again with refresh token
    // 5 . use that token and pass around
    // 6 . redirect user to home
    // 7 . success
    const refreshUrl = `${process.env.REACT_APP_MIDDLEWARE_URL}/evergent/bbc/refreshToken`;
    const refreshBody = {
      RefreshTokenRequestMessage: {
        grantType: "refresh_token",
        refreshToken: user.getRefreshToken(),
        operator: "astro",
      },
    };

    if (user.isUserSignedIn()) {
      makeRequest(refreshUrl, "POST", refreshBody)
        .then((data) => {
          setAppUserData(data.RefreshTokenResponseMessage);
        })
        .catch((err) => console.log(err));
    } else {
      makeRequest(url, "POST", body)
        .then((data) => {
          setAppUserData(data.GetOAuthAccessTokenRespMessage);
        })
        .catch((err) => console.log(err));
    }

    init();
    return () => {
      const branch = window.branch;
      if (branch) {
        branch.removeListener("didCloseJourney");
      }
    };
  }, []);

  const init = () => {
    if (document.querySelector("#branch-banner-iframe")) return;

    const branch = window.branch;

    // Initialize Branch if not already initialized
    branch.init(process.env.REACT_APP_BRANCH_KEY, {}, () => {
      branch.addListener("didCloseJourney", function (event, data) {
        if (
          window.location.search.includes(`${process.env.REACT_APP_BANNER_ID}`)
        ) {
          window.history.go(-1);
        }
      });
    });
  };

  useEffect(() => {
    const url = `${process.env.REACT_APP_MIDDLEWARE_URL}/navigation`;
    makeRequest(url, "GET", null, null)
      .then((data) => {
        if (data) {
          let settings = getStorageItem("settings", true, true) || {
            subtitle: "off",
            language: "English",
            quality: "Best (HD)",
            autoplay: "Yes (Default)",
            audio: "English",
          };
          const supportedLanguages = {};
          if (
            data.supportedSubtitleLanguages &&
            data.supportedSubtitleLanguages.length > 0
          ) {
            supportedLanguages.subtitle = data.supportedSubtitleLanguages;
            settings.language = data.supportedSubtitleLanguages?.find(
              (e) => e.label === "English"
            )
              ? "English"
              : settings.language;
          } else {
            supportedLanguages.subtitle = [];
            settings.subtitle = "Off";
          }
          settings.audio = "English";
          if (
            data.supportedAudioLanguages &&
            data.supportedAudioLanguages.length > 0
          ) {
            supportedLanguages.audio = data.supportedAudioLanguages;
          } else {
            supportedLanguages.audio = [
              { label: "English", iso1: "en", iso2: "qec" },
            ];
          }
          setStorageItem("settings", settings, true, true);
          setStorageItem("supportedLanguages", supportedLanguages, true, true);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (appUserData.accessToken !== "") {
      console.log(user);
      console.log(user.hasActiveSubscription());
    }
  }, [appUserData]);

  const [menuState, setMenuState] = useState({ colled: false });

  const [focusedMenu, setFocusedMenu] = useState(0);
  const [playerSource, setPlayerSource] = useState("Home");
  const menuTitles = ["Home", "Search", "Channels", "Categories"];

  const [activeScreen, setActiveScreen] = useState(menuTitles[focusedMenu]);

  const getActiveScreen = () => {
    let active = titleCase(location.pathname.split("/")[1]);
    active = active.length > 10 ? menuTitles[focusedMenu] : active;
    return active;
  };

  let focusNavMap = {
    SideMenu: {
      RIGHT: getActiveScreen(),
    },
    Home: {
      LEFT: "SideMenu",
    },
    Category: {
      LEFT: "SideMenu",
      BACK: "SideMenu",
    },
    Search: {
      LEFT: "SideMenu",
    },
    Settings: {
      LEFT: "SideMenu",
      BACK: "SideMenu",
    },
    Player: {
      RIGHT: "Home",
      BACK: playerSource,
    },
    Channel: {
      LEFT: "SideMenu",
      BACK: "SideMenu",
    },
    Brand: {
      LEFT: "SideMenu",
      BACK: "SideMenu",
      "": "SideMenu",
    },
  };
  const [showExitPrompt, setShowExitPrompt] = useState(false);
  const [suppressKeys, setSuppressKeys] = useState(false);

  const toggleAppExit = () => {
    if (focusedMenu >= 0) {
      setSuppressKeys(!showExitPrompt);
      setSuppressed(!showExitPrompt);
      setShowExitPrompt(!showExitPrompt);
    }
  };

  const [backRequested, setBack] = useState(false);

  useEffect(() => {
    if (backRequested) {
      let route = -1
      if(window?.location?.href?.includes("brand" ) && location?.state?.source !== "Categories" && location?.state?.source !=="Channels"){
        route  = '/home'
      }
      navigate(route)
      setBack(false);
    }
  }, [backRequested]);

  const toggleBack = () => {
    setBack(true);
  };

  const [
    currentFocusOwner,
    setFocusRequest,
    changeFocusOwner,
    unhandledAction,
    setUnHandledAction,
    setSuppressed,
  ] = useFocusParent(
    "Splash",
    focusNavMap,
    null,
    null,
    toggleAppExit,
    toggleBack,
    showAstroInfo,
    setShowAstroInfo
  );
  console.log(currentFocusOwner, "wowowowow");

  useEffect(() => {
    if (unhandledAction === "BACK") {
      toggleAppExit();
      setUnHandledAction("");
    }
  }, [unhandledAction]);

  return (
    <AstroBBCContext.Provider
      value={{
        userData: appUserData,
        deviceData: appDeviceInfo,
        auth: user,
        showLoading: setLoading,
        displayAstroInfo: setShowAstroInfo,
        showAstroInfo: showAstroInfo,
      }}
    >
      <ErrorBoundary>
        <div className="App">
          {loading && <LoadingScreen />}

          {/* <Splash hasFocus={currentFocusOwner==='Splash'} bubbleFocusUP={setFocusRequest} /> */}
          {/* <Login/> */}
          <Routes>
            <Route
              path="/"
              element={
                <Splash
                  hasFocus={currentFocusOwner === "Splash"}
                  bubbleFocusUP={setFocusRequest}
                  changeFocusOwnerApp={changeFocusOwner}
                />
              }
            />
            <Route
              element={
                <RouteWithSideMenu
                  suppressKeys={suppressKeys}
                  hasFocus={currentFocusOwner === "SideMenu"}
                  bubbleFocusUP={setFocusRequest}
                  setMenuSelection={setFocusedMenu}
                  changeFocusOwnerApp={changeFocusOwner}
                  setMenuState={setMenuState}
                />
              }
            >
              <Route
                path="/home"
                exact
                element={
                  <Home
                    showAstroInfo={showAstroInfo}
                    setShowAstroInfo={setShowAstroInfo}
                    toggleAppExit={toggleAppExit}
                    hasFocus={currentFocusOwner === "Home"}
                    bubbleFocusUP={setFocusRequest}
                    setPlayerSource={setPlayerSource}
                    changeFocusOwnerApp={changeFocusOwner}
                    suppressKeys={suppressKeys}
                  />
                }
              />
              <Route
                path="/channel/:path*"
                element={
                  <Channels
                    setPlayerSource={setPlayerSource}
                    toggleBack={toggleBack}
                    hasFocus={currentFocusOwner === "Channel"}
                    showAstroInfo={showAstroInfo}
                    setShowAstroInfo={setShowAstroInfo}
                    bubbleFocusUP={setFocusRequest}
                    changeFocusOwnerApp={changeFocusOwner}
                  />
                }
              />
              <Route
                path="/search"
                element={
                  <Search
                    hasFocus={currentFocusOwner === "Search"}
                    bubbleFocusUP={setFocusRequest}
                    changeFocusOwnerApp={changeFocusOwner}
                  />
                }
              />
              {/* /en/channels/:path* */}
              <Route
                path="/category/:path*"
                element={
                  <Categories
                    toggleBack={toggleBack}
                    hasFocus={currentFocusOwner === "Category"}
                    showAstroInfo={showAstroInfo}
                    setShowAstroInfo={setShowAstroInfo}
                    setPlayerSource={setPlayerSource}
                    bubbleFocusUP={setFocusRequest}
                    changeFocusOwnerApp={changeFocusOwner}
                  />
                }
              />
              <Route
                path="/brand/:path*"
                element={
                  <Series
                    toggleBack={toggleBack}
                    showAstroInfo={showAstroInfo}
                    setShowAstroInfo={setShowAstroInfo}
                    hasFocus={currentFocusOwner === "Brand"}
                    bubbleFocusUP={setFocusRequest}
                    menuState={menuState}
                    changeFocusOwnerApp={changeFocusOwner}
                    setPlayerSource={setPlayerSource}
                  />
                }
              />
            </Route>
            <Route
              path="/settings"
              element={
                <Settings
                  hasFocus={currentFocusOwner === "Settings"}
                  bubbleFocusUP={setFocusRequest}
                  changeFocusOwnerApp={changeFocusOwner}
                />
              }
            />
            <Route
              path="/player"
              element={
                <Player
                  hasFocus={currentFocusOwner === "Player"}
                  bubbleFocusUP={setFocusRequest}
                  changeFocusOwnerApp={changeFocusOwner}
                />
              }
            />
          </Routes>
          <Modal
            iswhiteBg={true}
            open={showExitPrompt}
            hasFocus={showExitPrompt}
            onClose={() => {
              setShowExitPrompt(false);
              setSuppressKeys(false);
              setSuppressed(false);
            }}
            showBtwo={true}
            buttonOneText={"Cancel"}
            buttonTwoText={"Exit"}
            onCancel={requestExitApp}
            title={"Are you sure you want to exit ?"}
          ></Modal>
          {showAstroInfo && (
            <div className="astro-more-info-container">
              <img className="astro-info-image" src={AstroMoreInfoImage} />
            </div>
          )}
        </div>
      </ErrorBoundary>
    </AstroBBCContext.Provider>
  );
}

export default App;
