import { useState, useEffect } from 'react'
import jwt_decode from "jwt-decode";
import { getStorageItem,removeStorageItem,setStorageItem } from '../utils/utils'

const useAuth = (userData = null, deviceInfo = null) => {
    
    const [decodedJWT, setDecodedJWT] = useState(null)
    const [authHeader, setAuthHeader] = useState("")
    const [deviceData, setDeviceData] = useState("")

    useEffect(()=> {
        if(userData.accessToken !== undefined && userData.accessToken !== ''){
            console.log("ACCESS TOKEN")
            console.log(userData.accessToken)
            let decoded = jwt_decode(userData.accessToken);
            let authHeaderStr = formatAuthHeader(userData.accessToken, userData.tokenType)
            setAuthHeader(authHeaderStr)
            setDecodedJWT(decoded)
            let currentEpochTime = Math.floor(new Date().getTime()/1000) + parseInt(userData.expiresIn)
            let userSignInInfo = {...userData, "expiresInEpoch" : currentEpochTime}
            //numbers aren't typo
            setStorageItem("astro-bbc-user-info", userSignInInfo, false, true, false)
        }
    }, [userData])

    useEffect(()=> {
      if(deviceInfo !== null){
        setDeviceData(deviceInfo)
      }
  }, [deviceInfo])



    const hasActiveSubscription = () =>{
        console.log(decodedJWT)
        if (decodedJWT === null) {
            return false
        }

        if(decodedJWT.entitlements && decodedJWT.entitlements.length > 0 ){
            console.log(decodedJWT)
            return true
        }
        return false
    }


    const signOutUser = async () => {
        console.log(authHeader)
        console.log(authHeader)
        let url = `${process.env.REACT_APP_EVERGENT_REST_URL}/removeDeviceForAccount`
        let apiUser = `${process.env.REACT_APP_EVERGENT_UN}`
        let apiPassword = `${process.env.REACT_APP_EVERGENT_PW}`
        //not able to pick this from config
        apiPassword = "9fn1uZHqVrr3x9A6"

        const response = await fetch(url, {
            method: "POST",
            headers: {
              "content-type": "application/json",
              "Authorization": authHeader
            },
            body: JSON.stringify({ RemoveDeviceForAccountRequestMessage: {
              "apiUser" : apiUser,
              "apiPassword" : apiPassword,
              "serialNo" : deviceData.deviceID
            } })
          });
          try {
            const data = await response.json();
            removeStorageItem("astro-bbc-user-info")
            setDecodedJWT(null)
            console.log(data)
            return data.RemoveDeviceForAccountResponseMessage.responseMessage
            
          } catch(err) {
            console.log(err)
          }
     }


    const hasAuthorizedPackage = async (contentItem) => {
        console.log(authHeader)
        console.log(authHeader)
        let url = `${process.env.REACT_APP_MIDDLEWARE_URL}/playback/authorized`
        const response = await fetch(url, {
            method: "POST",
            headers: {
              "content-type": "application/json",
              "Authorization": authHeader
            },
            body: JSON.stringify({ channel: contentItem.channel })
          });
          try {
            const data = await response.json();
            return data.success;
          } catch(err) {
            return false
          }
     }

     const updatePlayerProgress = async(contentItem, progress) => {
       console.log(contentItem)
      let url = `${process.env.REACT_APP_MIDDLEWARE_URL}/playback/update`
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "Authorization": authHeader
        },
        body: JSON.stringify({ contentId: contentItem.id, seriesId : contentItem.seriesId, progress : progress })
      });
      try {
        const data = await response.json();
        return data.success;
      } catch(err) {
        return false
      }
     }
    

     const formatAuthHeader = (token, authType) => {
         return authType + " " + token
     }

    const isUserSignedIn = () => {
      let userInfoLocal = getStorageItem("astro-bbc-user-info")
      if (userInfoLocal === null){
        return false
      }else{
        return true
      }
    }
    const getRefreshToken = () => {
      let userInfoLocal = getStorageItem("astro-bbc-user-info", false,true,false)
      if(userInfoLocal !== null ){
        userInfoLocal =userInfoLocal.refreshToken
      }
      return userInfoLocal
    }

    const getAuthHeader = () => {
      let userInfoLocal = getStorageItem("astro-bbc-user-info", false,true,false)
      if(userInfoLocal !== null ){
        return formatAuthHeader(userInfoLocal.accessToken, userInfoLocal.tokenType)
      }
      
    }


    return {
        hasActiveSubscription,
        hasAuthorizedPackage,
        signOutUser,
        isUserSignedIn,
        getRefreshToken,
        getAuthHeader,
        updatePlayerProgress
    }


}

export default useAuth

/*
accessToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJlbnRpdGxlbWVudHMiOlt7InByb2R1Y3RJRCI6IkJCQ19FRFVDQVRJT04iLCJwcm9kdWN0TmFtZSI6IkJCQ19FRFVDQVRJT04iLCJwcm9kdWN0VHlwZSI6IlBST0RVQ1QifSx7InByb2R1Y3RJRCI6IkJCQ19MSUZFU1RZTEUiLCJwcm9kdWN0TmFtZSI6IkJCQ19MSUZFU1RZTEUiLCJwcm9kdWN0VHlwZSI6IlBST0RVQ1QifSx7InByb2R1Y3RJRCI6IkJCQ19CUklUIiwicHJvZHVjdE5hbWUiOiJCQkNfQlJJVCIsInByb2R1Y3RUeXBlIjoiUFJPRFVDVCJ9LHsicHJvZHVjdElEIjoiQkJDX0ZJUlNUX0hEIiwicHJvZHVjdE5hbWUiOiJCQkNfRklSU1RfSEQiLCJwcm9kdWN0VHlwZSI6IlBST0RVQ1QifV0sInN1YiI6IjIyMDkxNTA2MDcwMjc1NzY2Njc0MTgyIiwib3AiOiJCQkNfQVNUUk8iLCJpc3MiOiJldmVyZ2VudCIsImNwIjoiQkJDX0FTVFJPIiwiYXVkIjoiYmJjcGxheWVyIiwidWlkIjoiN0FDRDRENTk5RUQ3RDg1Q0U4QTRDNUUyRkEwMzAyIiwibmJmIjoxNjY0MzUyNzc5LCJleHAiOjE2NjQzNTYzODQ3NDYsImlhdCI6MTY2NDM1Mjc4NCwianRpIjoiS1JSdi1XMTcxLW1pR0ItOE9aRy00cDdvLTVEaTItRUIiLCJjaWQiOiI2NzM5NDc3MSJ9.w21yX6pX1YSR42ETUqI_26tcTRTNTtHJv4GGMZBSKCq1NRmGmoVHfzGdxu2DhK-26pQxfs7i7IG2_8FU1tI1LbGqwxKynCkG4LiWOK9lYsYP0IafrVAA5S6X76E9r9O5LsoE-DYaRS5qjAop6wLuc28hw-U_XtDgLcuO1o8gY8dL578OD4O-BeSxuYScKhe7ip0UrLAYt9_YbwfHkNY162FYxE29F67UaTCbmXGRWd8KFXphyyQLsbQra8M4qF20snER3PeI0QcU54XwQIhog69RGMiftjjx8KltO0KbFCnSr8RWNhjr8bg7QnEbhnRVhltPmNVuUTBXqogRLKH5Gg"
expiresIn: "3600"
refreshToken: "kVBO-v6gM-to8O-uRLG-0rXw-AUxG-rX"
responseCode: "0"
responseMessage: "SUCCESS"
tokenType: "Bearer"
*/
