import React, {
  useEffect,
  useState,
  useReducer,
  useRef,
  useCallback,
  useContext,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useRemoteKeyEvent from "../../hooks/useRemoteKeyEvent";
import { getStorageItem, setStorageItem } from "../../utils/utils";
import Card from "../Card/Card";
import "./Slider.css";
import Modal from "../Modals/Modal";
import { AstroBBCContext } from "../../App";
import { memo } from "react";

function reducer(state, action) {
  let newisVisibleTracker = [];
  const selected = Number(getStorageItem("savedCardIndex"));
  switch (action.type) {
    case "HAS_FOCUS":
      for (let i = 0; i < state.items.length; i++) {
        i <= state.totalVisibleCards
          ? (newisVisibleTracker[i] = true)
          : (newisVisibleTracker[i] = false);
      }
      if (selected) {
        if (
          state.selectedIndex >= state.totalVisibleCards &&
          state.lastIndex + 1 < state.items.length
        ) {
          newisVisibleTracker[state.firstIndex] = false;
          newisVisibleTracker[state.lastIndex + 1] = true;
        }
      }
      return {
        ...state,
        selectedIndex: selected || 0,
        isVisibleTracker: newisVisibleTracker,
        firstIndex:
          state.selectedIndex >= state.totalVisibleCards &&
          state.lastIndex + 1 < state.items.length
            ? state.firstIndex + 1
            : state.firstIndex,
        lastIndex:
          state.selectedIndex >= state.totalVisibleCards &&
          state.lastIndex + 1 < state.items.length
            ? state.lastIndex + 1
            : state.lastIndex,
      };
    case "ARROW_RIGHT":
      newisVisibleTracker = [...state.isVisibleTracker];
      //Only update if current selected card is the right most one in the view and it is not the last card
      if (
        state.selectedIndex >= state.totalVisibleCards &&
        state.lastIndex + 1 < state.items.length
      ) {
        newisVisibleTracker[state.firstIndex] = false;
        newisVisibleTracker[state.lastIndex + 1] = true;
      }
      return {
        ...state,
        selectedIndex:
          state.selectedIndex !== state.items.length - 1
            ? state.selectedIndex + 1
            : state.selectedIndex,
        isVisibleTracker: newisVisibleTracker,
        firstIndex:
          state.selectedIndex >= state.totalVisibleCards &&
          state.lastIndex + 1 < state.items.length
            ? state.firstIndex + 1
            : state.firstIndex,
        lastIndex:
          state.selectedIndex >= state.totalVisibleCards &&
          state.lastIndex + 1 < state.items.length
            ? state.lastIndex + 1
            : state.lastIndex,
      };
    case "ARROW_LEFT":
      newisVisibleTracker = [...state.isVisibleTracker];
      console.log(action);
      //Only update if current selected card is the left most one in the view and it is not the first card
      if (
        state.firstIndex > 0 &&
        state.selectedIndex <= state.items.length - 1 - state.totalVisibleCards
      ) {
        newisVisibleTracker[state.firstIndex - 1] = true;
        newisVisibleTracker[state.lastIndex] = false;
      }
      return {
        ...state,
        selectedIndex:
          state.selectedIndex !== 0
            ? state.selectedIndex - 1
            : state.selectedIndex,
        isVisibleTracker: newisVisibleTracker,
        firstIndex:
          state.firstIndex > 0 &&
          state.selectedIndex <=
            state.items.length - 1 - state.totalVisibleCards
            ? state.firstIndex - 1
            : state.firstIndex,
        lastIndex:
          state.firstIndex > 0 &&
          state.selectedIndex <=
            state.items.length - 1 - state.totalVisibleCards
            ? state.lastIndex - 1
            : state.lastIndex,
      };
    case "UPDATE_ITEMS":
      for (let i = 0; i < action.payload.items.length; i++) {
        i <= state.totalVisibleCards
          ? (newisVisibleTracker[i] = true)
          : (newisVisibleTracker[i] = false);
      }
      if (selected) {
        if (
          state.selectedIndex >= state.totalVisibleCards &&
          state.lastIndex + 1 < state.items.length
        ) {
          newisVisibleTracker[state.firstIndex] = false;
          newisVisibleTracker[state.lastIndex + 1] = true;
        }
      }
      return {
        ...state,
        items: action.payload.items,
        isVisibleTracker: newisVisibleTracker,
        selectedIndex: selected,
        firstIndex:
          state.selectedIndex >= state.totalVisibleCards &&
          state.lastIndex + 1 < state.items.length
            ? state.firstIndex + 1
            : state.firstIndex,
        lastIndex:
          state.selectedIndex >= state.totalVisibleCards &&
          state.lastIndex + 1 < state.items.length
            ? state.lastIndex + 1
            : state.lastIndex,
      };
    default:
      throw new Error();
  }
}

const Slider = ({
  selectedCaurosalIndex,
  hasFocus,
  bubbleFocusUP,
  holdFocus,
  source,
  isSelected,
  collection,
  changeFocusOwnerApp,
  suppressKeys,
  suppressList,
  setPlayerSource,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const dummyFunc = () => {};

  bubbleFocusUP = holdFocus ? dummyFunc : bubbleFocusUP;
  const [keyState, setFocus, setSuppressed] = useRemoteKeyEvent(
    ["LEFT", "RIGHT", "OK", "BACK"],
    bubbleFocusUP,
    hasFocus
  );

  const [isOpen, setIsOpen] = useState(false);
  const appData = useContext(AstroBBCContext);

  //references to carousal and card in DOM
  const cardRef = useRef(null);
  const carousalRef = useRef(null);
  // Setting the number of cards inside a carousal
  let totalVisibleCards = 4;

  // The carousal has a number of visible cards
  // The remaining cards are hided initially
  // The cards are hided and un-hided with respect to key events
  // An array is used to track the visibility of cards
  // Two index refences (firstIndex,lastIndex) are used to track first and last visible cards in the carousal.
  // Intially the first card is selected by default.
  const initialState = {
    items: [],
    selectedIndex: 0,
    isVisibleTracker: [],
    totalVisibleCards,
    firstIndex: 0,
    lastIndex: totalVisibleCards,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  // setting the focus
  useEffect(() => {
    if (hasFocus) {
      setFocus();
      dispatch({ type: "HAS_FOCUS" });
    }
  }, [hasFocus]);

  // Setting the totalVisibleCards when width of carousal or cards are changed
  // useEffect(() => {
  //   if (carousalRef?.current && cardRef?.current) {
  //     // setTotalVisibleCards(
  //     //   Math.floor(
  //     //     carousalRef?.current.clientWidth / cardRef?.current.clientWidth
  //     //   )
  //     // );
  //     console.log(totalVisibleCards);
  //     let payload = { items, totalVisibleCards };
  //     dispatch({ type: "UPDATE_ITEMS", payload });
  //   }
  // }, [cardRef, carousalRef]);

  //Event handlers for key events
  useEffect(() => {
    if (keyState.RIGHT) {
      console.log("rightPressed");
      dispatch({ type: "ARROW_RIGHT" });
    }
    if (keyState.LEFT) {
      if (state.selectedIndex === 0) {
        bubbleFocusUP({ relieveFocus: true, action: "LEFT" });
      } else {
        dispatch({ type: "ARROW_LEFT" });
      }
    }
    if (keyState.OK) {
      setStorageItem(
        "savedCardIndex",
        state.selectedIndex,
        false,
        false,
        false
      );
      setStorageItem(
        "savedCaurosalIndex",
        selectedCaurosalIndex,
        false,
        false,
        false
      );
      let selectedItem = state.items[state.selectedIndex];
      console.log(selectedItem);
      console.log(source);
      switch (selectedItem.__typename) {
        case "Episode":
          if (appData.auth.hasActiveSubscription(selectedItem)) {
            appData.auth.hasAuthorizedPackage(selectedItem).then((data) => {
              if (data) {
                changeFocusOwnerApp("Player");
                navigate("/player", {
                  state: {
                    title: "Show title",
                    source: source,
                    subtitle: "Subtitle",
                    metadata: selectedItem,
                  },
                });
                if (setPlayerSource) {
                  setPlayerSource(source);
                }
              } else {
                setIsOpen(true);
                setSuppressed(true);
                if (suppressList) {
                  suppressList(true);
                }
              }
            });
            //
          } else {
            //show pop up
            if (appData.auth.isUserSignedIn()) {
              setIsOpen(true);
              setSuppressed(true);
              if (suppressList) {
                suppressList(true);
              }
              //navigate('/player', { state: { title: "Show title", subtitle: "Subtitle", metadata : selectedItem } });
            } else {
              navigate("/",{state:{pathname:location?.pathname}});
              changeFocusOwnerApp("Splash");
            }
          }

          break;
        case "Series":
          changeFocusOwnerApp("Brand");
          navigate(`/brand${selectedItem.path}`, {
            state: { path: selectedItem.path, source: source },
          });
          break;
        case "Season":
          changeFocusOwnerApp("Brand");
          navigate(`/brand${selectedItem.series.path}`, {
            state: { path: selectedItem.series.path, source: source },
          });
          break;

        default:
          break;
      }
      //navigate(`/item/:${selectedItem?.id}`);
    }
  }, [keyState]);

  useEffect(() => {
    if (suppressKeys) {
      setSuppressed(suppressKeys);
    } else {
      setSuppressed(suppressKeys);
    }
  }, [suppressKeys]);

  useEffect(() => {
    if (!appData.showAstroInfo) {
      setSuppressed(false);
      if (suppressList) {
        suppressList(false);
      }
    }
  }, [appData.showAstroInfo]);

  const redirectToSignIn = () => {
    //changeFocusOwnerApp("Splash")
    //navigate('/');
    setIsOpen(false);
    suppressList(true);
    setSuppressed(true);
    appData.displayAstroInfo(true);
  };

  //Update carousal content
  useEffect(() => {
    //Getting the contents of the collection prop
    let items = collection?.items?.nodes;
    let payload = { items };
    if (items) {
      dispatch({ type: "UPDATE_ITEMS", payload });
    }
  }, [collection]);

  return (
    <div className={hasFocus ? ".slider-focused" : "carousal-outer-container"}>
      <div className="carousal-container" ref={carousalRef}>
        {state.items.map(
          (item, index) =>
            state.isVisibleTracker[index] && (
              <div className="cards" ref={cardRef} key={item.id}>
                <Card
                  isSelected={index === state.selectedIndex && hasFocus}
                  item={item}
                />
              </div>
            )
        )}
      </div>
      <Modal
        iswhiteBg={true}
        open={isOpen}
        hasFocus={isOpen}
        onClose={redirectToSignIn}
        showBtwo={true}
        buttonOneText={"Upgrade now"}
        buttonTwoText={"Upgrade later"}
        onCancel={() => {
          setIsOpen(false);
          setSuppressed(false);
          if (suppressList) {
            suppressList(false);
          }
        }}
        title={"You currently don't have the right pack to watch this content"}
      ></Modal>
    </div>
  );
};

export default memo(Slider);
