import './SubtitleSettings.css';
import SubtitleIcon from '../../assets/icons/player/subtitle-icon.png';
import { useRef, useEffect, useState } from 'react';
import useRemoteKeyEvent from '../../hooks/useRemoteKeyEvent';
import SubtitleItem from './SubtitleItem';
import { getLanguageKey } from '../../utils/languageMapper';

const SubtitleSettings = ({ hasFocus, bubbleFocusUP, closeCCSettings, enableCC, availCCs, chosenCC, isAudio }) => {
    const [keyState, setFocus, setSuppressed] = useRemoteKeyEvent(
        ['OK', 'BACK', 'UP', 'DOWN', 'RIGHT', 'LEFT'],
        bubbleFocusUP,
        hasFocus
    );
    const [ccEnabled, setCCEnabled] = useState(false);
    const [ccOptions, setCCOptions] = useState(isAudio ? [] : ['none']);
    const [focusedIndex, setFocusedIndex] = useState(0);

    useEffect(() => {
        if (!isAudio && availCCs.length > 0) {
            setCCOptions(['none', ...availCCs]);
        }
        if (isAudio && availCCs.length > 0) {
            setCCOptions(availCCs);
        }
    }, [availCCs]);

    useEffect(() => {
        if (hasFocus) {
            setFocus();
        }
    }, [hasFocus]);

    useEffect(() => {
        if (keyState.OK) {
            setCCEnabled(!ccEnabled);
            if (!ccEnabled) {
                const langChose = !isAudio ? focusedIndex === 0 ? 'none' : availCCs[focusedIndex - 1] : availCCs[focusedIndex];
                enableCC(langChose);
            }
            closeCCSettings();
        }

        if (keyState.BACK) {
            closeCCSettings();
        }

        if (keyState.UP) {
            if (focusedIndex === 0) {
                setFocusedIndex(0)
            } else {
                setFocusedIndex(focusedIndex => focusedIndex - 1)
            }
        }

        if (keyState.DOWN) {
            if (focusedIndex === ccOptions.length - 1) {
                setFocusedIndex(ccOptions.length - 1)
            } else {
                setFocusedIndex(focusedIndex => focusedIndex + 1)
            }
        }

        if (keyState.LEFT) {
            closeCCSettings();
        }
    }, [keyState]);

    useEffect(() => {
        if (ccOptions.length > 0) {
            setFocusedIndex(ccOptions.indexOf(chosenCC));
        }
    }, [chosenCC, ccOptions]);

    return (
        <div className="subtitle-settings-container">
            <div className="subtitle-settings-left-bar" />
            <div className="subtitle-settings-selector">
                <p className="subtitle-label">{isAudio ? 'Audio' : 'Subtitles'}</p>
                <ul className="subtitle-item-list">
                    {ccOptions.map((item, index) => (
                        <li key={index}>
                            <SubtitleItem
                                hasFocus={index === focusedIndex}
                                data={{ language: getLanguageKey(item) }}
                                chosen={item === chosenCC}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default SubtitleSettings;