import React, { useState,useEffect } from 'react';
import useRemoteKeyEvent from '../../../hooks/useRemoteKeyEvent';
import './AVSettings.css'
import { setStorageItem,getStorageItem } from '../../../utils/utils';

const AVSettings = (props) => {

    let [focusPoint, setFocusPoint] = useState({ "x": 1, "y": 0 });
    let [selected, setSelected] = useState();
    const [keyState, setFocus] = useRemoteKeyEvent(['DOWN', 'UP', 'OK', "RIGHT", "LEFT", 'BACK'], props.bubbleFocusUP, props.hasFocus)
    const [isFetchingLocalData, setStatus] = useState(true)
    const scrollDiv = document.getElementById('outerDivToScroll')
    const selectedMapper = (y) => {
        switch (y) {
            case 0:
                return "subtitle";
            case 1:
                return "language";
            case 2: 
                return "quality";
            case 3: 
                return "autoplay";
            case 5: 
                return "audio";
            default:
                return;
        }
    };
    
    useEffect(() => {
        let existingSettings = getStorageItem("settings", true, true);
        if (existingSettings){
            setSelected(existingSettings);
        }else {
            //Default Settings
            //TODO : This needs to be moved to a place after login succeeds
            setSelected({"subtitle":"Off","language":"English CC","quality":"Best (HD)","autoplay":"Yes (Default)","audio": "English (Default)"});
        }
        setStatus(false)
    }, [])

    useEffect(() => {
        //After changing the selected state save it locally
        setStorageItem("settings",selected,true,true)
        console.log("Jenix the updated State value is:"+JSON.stringify(selected))
    }, [selected])

    useEffect(() => {
        if (props.hasFocus) {
            setFocusPoint({ "x": 1, "y": 0})
            setFocus()
            console.log('AV Settings Div Gained Focus')
        } else {
            setFocusPoint({ "x": -1, "y": -1})
            console.log('AV Settings Div Lost Focus')
        }
    }, [props.hasFocus])

    let languages = getStorageItem("supportedLanguages", true, true);
    const subtitleLanguages = languages?.subtitle?.map( (e, i) => {
        return {
            keycode : e.label,
            xIndex: i + 1,
            yIndex: 1    
        }
    } );
    const audioLanguages = languages?.audio?.map( (e, i) => {
        return {
            keycode : e.label,
            xIndex: i + 1,
            yIndex: 5   
        }
    } );

    let settingsKeys = [
        [
            { "keycode": "Subtitles", "xIndex": 0, "yIndex": 0, "span":3 },
            { "keycode": "On", "xIndex": 1, "yIndex": 0 },
            { "keycode": "Off", "xIndex": 2, "yIndex": 0 }
        ],
        [
            { "keycode": "Language", "xIndex": 0, "yIndex": 1, "span":3 },
            ...subtitleLanguages
        ],
        [
            { "keycode": "Playback quality", "xIndex": 0, "yIndex": 2, "span":3 },
            { "keycode": "Standard (SD)", "xIndex": 1, "yIndex": 2 },
            { "keycode": "Best (HD)", "xIndex": 2, "yIndex": 2 }
        ],
        [
            { "keycode": "Autoplay", "xIndex": 0, "yIndex": 3, "span":3},
            { "keycode": "Yes (Default)", "xIndex": 1, "yIndex": 3},
            { "keycode": "No", "xIndex": 2, "yIndex": 3}
        ],
        [
            { "keycode": "After you finish watching an episode, the next one plays automatically.", "xIndex": 0, "yIndex": 4, "span":6}
        ],
        [
            {"keycode":"Audio language", "xIndex": 0, "yIndex": 5, "span":3},
            ...audioLanguages
        ]   

    ]

    let MAX_ROW = 5;
    let MAX_COL = 5;

    useEffect(() => {
        let currentFocuspoint = focusPoint;
        if (keyState.RIGHT) {
            const focusedDiv = document.getElementById('focusedDiv');
            const focusedRect = focusedDiv.getBoundingClientRect();
            const focusedTopRelativeToParent = focusedRect.top;
            console.log("The right value is:"+JSON.stringify(focusedRect))
            if (focusedTopRelativeToParent > 800) {
                scrollDiv.scrollBy(0,200);
            }
            let newFocusPoint = {}
            console.log("Pressing right the current focus point is:"+JSON.stringify(currentFocuspoint))
            if ((currentFocuspoint.y === 0 || currentFocuspoint.y === 2 || currentFocuspoint.y === 3) && currentFocuspoint.x < 2){
                newFocusPoint.x = currentFocuspoint.x + 1
                newFocusPoint.y = currentFocuspoint.y
                console.log("Pressing right the new focus point is:"+JSON.stringify(newFocusPoint))
                setFocusPoint(newFocusPoint)
            }else {
                if ( (currentFocuspoint.y === 1 && currentFocuspoint.x < subtitleLanguages.length ) || ( currentFocuspoint.y === 5 && currentFocuspoint.x < audioLanguages.length)){
                    newFocusPoint.x = currentFocuspoint.x + 1
                    newFocusPoint.y = currentFocuspoint.y
                    console.log("Pressing right the new focus point is:"+JSON.stringify(newFocusPoint))
                    setFocusPoint(newFocusPoint)
                }
            } 
        }
        else if (keyState.LEFT) {
            const focusedDiv = document.getElementById('focusedDiv');
            const focusedRect = focusedDiv.getBoundingClientRect();
            const focusedTopRelativeToParent = focusedRect.top;
            console.log("The left value is:"+JSON.stringify(focusedRect))
            if (focusedTopRelativeToParent <= 800) {
                scrollDiv.scrollBy(0,-200);
            }
            let newFocusPoint = {}
            if (currentFocuspoint.x > 1) {
                newFocusPoint.x = currentFocuspoint.x - 1
                newFocusPoint.y = currentFocuspoint.y
                setFocusPoint(newFocusPoint)
            }else {
                props.bubbleFocusUP({ relieveFocus : true, action : "LEFT"})
            }
        }
        else if (keyState.DOWN) {
            const focusedDiv = document.getElementById('focusedDiv');
            const focusedRect = focusedDiv.getBoundingClientRect();
            const focusedTopRelativeToParent = focusedRect.top;
            console.log("The down value is:"+JSON.stringify(focusedRect))
            if (focusedTopRelativeToParent > 800) {
                scrollDiv.scrollBy(0,200);
            }
            let newFocusPoint = {}
            console.log("The current focus point is:"+JSON.stringify(currentFocuspoint))
            if (currentFocuspoint.y === 3) {
                newFocusPoint.y = currentFocuspoint.y + 2
                newFocusPoint.x = currentFocuspoint.x <= settingsKeys[newFocusPoint.y].length - 1 ? currentFocuspoint.x : settingsKeys[newFocusPoint.y].length - 1
                setFocusPoint(newFocusPoint)
            }
            else if (currentFocuspoint.y === 0) {
                newFocusPoint.y = settingsKeys[currentFocuspoint.y + 1].length > 1 ? 1 : 2;
                newFocusPoint.x = currentFocuspoint.x <= settingsKeys[newFocusPoint.y].length - 1 ? currentFocuspoint.x : settingsKeys[newFocusPoint.y].length - 1
                setFocusPoint(newFocusPoint)
            }
            else if (currentFocuspoint.y === 1 && settingsKeys[1].length > 4) {
                const currentRowLength = settingsKeys[1].length;
                const nextRowLength = settingsKeys[2]?.length || 0;
                const isAtEndOfRow = currentFocuspoint.x === currentRowLength - 1;
                const willExceedRow = currentFocuspoint.x + 3 > currentRowLength - 1;
            
                if (isAtEndOfRow || willExceedRow) {
                    if (currentFocuspoint.x % 3 === 0) {
                        newFocusPoint.y = isAtEndOfRow ? currentFocuspoint.y + 1 : currentFocuspoint.y;
                        newFocusPoint.x = settingsKeys[newFocusPoint.y]?.length - 1 || 1;
                    } else {
                        newFocusPoint.y = currentFocuspoint.y + 1;
                        newFocusPoint.x = Math.min(currentFocuspoint.x % 3, nextRowLength - 1);
                    }
                } else {
                    newFocusPoint.y = currentFocuspoint.y;
                    newFocusPoint.x = currentFocuspoint.x + 3;
                }
            
                setFocusPoint(newFocusPoint);
            }
            else if (currentFocuspoint.y === 5 && settingsKeys[5].length > 4) {
                const currentRowLength = settingsKeys[5].length;
                const nextRowLength = settingsKeys[6]?.length || 0;
                const isAtEndOfRow = currentFocuspoint.x === currentRowLength - 1;
                const willExceedRow = currentFocuspoint.x + 3 > currentRowLength - 1;
            
                if (isAtEndOfRow || willExceedRow) {
                    // Do not change focus if at the end of the row or if exceeding the row
                    newFocusPoint.y = currentFocuspoint.y;
                    newFocusPoint.x = currentFocuspoint.x;
                } else {
                    newFocusPoint.y = currentFocuspoint.y;
                    newFocusPoint.x = currentFocuspoint.x + 3;
                }
                setFocusPoint(newFocusPoint);
            }
            else if (currentFocuspoint.y < MAX_COL ) {
                newFocusPoint.y = currentFocuspoint.y + 1
                newFocusPoint.x = currentFocuspoint.x <= settingsKeys[newFocusPoint.y].length - 1 ? currentFocuspoint.x : settingsKeys[newFocusPoint.y].length - 1
                setFocusPoint(newFocusPoint)
            }
        }
            
        else if (keyState.UP) {
            const focusedDiv = document.getElementById('focusedDiv');
            const focusedRect = focusedDiv.getBoundingClientRect();
            const focusedTopRelativeToParent = focusedRect.top;
            console.log("The up value is:"+JSON.stringify(focusedRect))
            if (focusedTopRelativeToParent <= 800) {
                scrollDiv.scrollBy(0,-200);
            }
            let newFocusPoint = {}
            if (currentFocuspoint.y > 0) {
                if (currentFocuspoint.y === 1) {
                    const currentRowLength = settingsKeys[1]?.length || 0;
                    const previousRowLength = settingsKeys[0]?.length || 0;
                    const isAtStartOfRow = currentFocuspoint.x === 0;
                    const willExceedPreviousRow = currentFocuspoint.x - 3 < 1;
                
                    if (isAtStartOfRow || willExceedPreviousRow) {
                        // Move focus to the first row if at the start or would exceed the previous row
                        newFocusPoint.y = 0;
                        newFocusPoint.x = Math.min(currentFocuspoint.x, previousRowLength - 1);
                    } else {
                        newFocusPoint.y = 1;
                        newFocusPoint.x = Math.min(currentFocuspoint.x - 3, currentRowLength - 1);
                    }
                } 
                else if (currentFocuspoint.y === 5 && settingsKeys[5].length > 4) {
                    const currentRowLength = settingsKeys[5].length;
                    const previousRowLength = settingsKeys[3]?.length || 0;
                    const isAtStartOfRow = currentFocuspoint.x === 0;
                    const willExceedPreviousRow = currentFocuspoint.x - 3 < 1;
                
                    if (isAtStartOfRow || willExceedPreviousRow) {
                        // Move focus up two rows if at the start or would exceed previous row
                        newFocusPoint.y = currentFocuspoint.y - 2 >= 0 ? currentFocuspoint.y - 2 : 0;
                        newFocusPoint.x = Math.min(currentFocuspoint.x, settingsKeys[newFocusPoint.y].length - 1);
                    } else {
                        // Move focus up one row, adjusting x accordingly
                        newFocusPoint.y = currentFocuspoint.y;
                        newFocusPoint.x = Math.min(currentFocuspoint.x - 3, currentRowLength - 1);
                    }
                }
                
                else if (currentFocuspoint.y === 5){
                    newFocusPoint.y = currentFocuspoint.y - 2
                    newFocusPoint.x = currentFocuspoint.x <= settingsKeys[newFocusPoint.y].length - 1 ? currentFocuspoint.x : settingsKeys[newFocusPoint.y].length - 1
                } else if (currentFocuspoint.y === 2) {
                    newFocusPoint.y = settingsKeys[currentFocuspoint.y - 1].length > 1 ? 1 : 0;
                    if (newFocusPoint.y === 1 && settingsKeys[newFocusPoint.y].length > 4) {
                        const remainder = settingsKeys[newFocusPoint.y].length % 3;
                        if (remainder === 0) {
                            newFocusPoint.x = settingsKeys[newFocusPoint.y].length - (currentFocuspoint.x === 1 ? 2 : 1);
                        } else if (remainder === 2) {
                            newFocusPoint.x = settingsKeys[newFocusPoint.y].length - 1;
                        } else if (remainder === 1) {
                            newFocusPoint.x = settingsKeys[newFocusPoint.y].length - (currentFocuspoint.x === 1 ? 3 : 2);
                        }
                    } else {
                        newFocusPoint.x = Math.min(currentFocuspoint.x, settingsKeys[newFocusPoint.y].length - 1);
                    }
                } else{
                    newFocusPoint.y = currentFocuspoint.y - 1
                    newFocusPoint.x = currentFocuspoint.x <= settingsKeys[newFocusPoint.y].length - 1 ? currentFocuspoint.x : settingsKeys[newFocusPoint.y].length - 1
                }
                
                setFocusPoint(newFocusPoint)
            }
        }
        else if (keyState.OK) {
            //Save data to local storage
            let valToSave = selected
            if (currentFocuspoint.y === 0){
                setSelected(prevState => ({...prevState,subtitle:settingsKeys[currentFocuspoint.y][currentFocuspoint.x].keycode}))
            }else if (currentFocuspoint.y === 1){
                setSelected(prevState => ({...prevState,language:settingsKeys[currentFocuspoint.y][currentFocuspoint.x].keycode}))
            }else if (currentFocuspoint.y === 2){
                setSelected(prevState => ({...prevState,quality:settingsKeys[currentFocuspoint.y][currentFocuspoint.x].keycode}))
            }else if (currentFocuspoint.y === 5){
                setSelected(prevState => ({...prevState,audio:settingsKeys[currentFocuspoint.y][currentFocuspoint.x].keycode}))
            } else if (currentFocuspoint.y === 3) {
                setSelected(prevState => ({...prevState,autoplay:settingsKeys[currentFocuspoint.y][currentFocuspoint.x].keycode}))
            }
        }
        else if (keyState.BACK){
            props.backNavFromSettings()
        }

    }, [keyState]);

    return (
        
        <div className="avContainer">
            <div className='avTitle'>AV settings</div>
            <div className='divLine'></div>
            <div id='outerDivToScroll' className = "settingsPanel" > 
            { 
                isFetchingLocalData ? (<div>Loading</div>) : (settingsKeys.map((row, rowIndex ) => {
                    return (
                        <div className={`settingsRow ${rowIndex === 1 || rowIndex === 5 ? 'settingsRowlanguage' : ''}`}>{ row.map((item, colIndex) => {
                            let style = "settingsDiv"
                            if (item.keycode === "Subtitles"){
                                style = style + " subtitles labelBold"
                            } else if (item.keycode === "Language"){
                                style = style + " language labelBold"
                            }
                            else if (item.keycode === "Playback quality"){
                                style = style + " playbackQuality labelBold"
                            }
                            else if (item.keycode === "Autoplay"){
                                style = style + " autoplay labelBold"
                            }
                            else if (item.span === 6 && item.keycode === "After you finish watching an episode, the next one plays automatically."){
                                style = style + " longtext"
                            }
                            else if (item.keycode === "Audio language"){
                                style = style + " audio labelBold"
                            }
                            else if (item.keycode === ""){
                                return <div></div>
                            }
                            else {
                                style = style + " border"

                                if (selected[selectedMapper(item.yIndex)] === item.keycode) {
                                    style += " selected"
                                }
                            }

                            if (item.xIndex === focusPoint.x && item.yIndex === focusPoint.y) {
                                style = style + "  focused"
                                if (!style.includes("selected")){
                                    style = style + " unselectedFocus"
                                }
                            }

                            return <div key={'key' + rowIndex + "_" + colIndex} id={style.includes("focused") ? 'focusedDiv': ''} className={style}>{item.keycode}</div>
                                
                        })} </div>
                    )
                })) 
            }
            </div>
        </div>
    )

}

export default AVSettings